.banner {
	margin-top: 0;
	padding: 200px 0 250px 0;
	background-image: url('../assets/img/banner-2.jpg');
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 95vh;
	/* filter: blur(); */
	opacity: 100%;
}
.banner .tagline {
	font-weight: 700;
	letter-spacing: 0.8px;
	padding: 8px 10px;
	background: linear-gradient(
		90.21deg,
		rgba(170, 54, 124, 0.5) -5.91%,
		rgba(74, 47, 189, 0.5) 111.58%
	);
	border: 1px solid rgba(255, 255, 255, 0.5);
	font-size: 20px;
	margin-bottom: 16px;
	display: inline-block;
}
.banner h1 {
	font-size: 65px;
	font-weight: 800;
	outline: none;
	background: linear-gradient(
		135deg,
		#5335cf 0%,
		#de005e 25%,
		#f66e48 50%,
		#de005e 75%,
		#5335cf 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: animate 10s linear infinite;
}

@keyframes animate {
	100% {
		background-position: 400%;
	}
}
.banner p {
	color: #666;
	font-size: 24px;
	font-weight: 300;
	letter-spacing: 0.8px;
	line-height: 1.3em;
	width: 96%;

	/* -webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: animate 10s linear infinite;
	background-image: linear-gradient(
		109.6deg,
		rgb(80, 130, 188) 11.2%,
		rgb(113, 185, 155) 30.1%,
		rgb(17, 125, 152) 98.7%
	); */
}
.banner p span {
	color: #fff;
	font-weight: bolder;
	font-size: 25px;
}

.banner button {
	font-weight: 700;
	font-size: 20px;
	margin-top: 60px;
	letter-spacing: 0.8px;
	display: flex;
	align-items: center;
	border: 0.3px solid#612d8f;
	padding: 10px;
	animation: animate 5s linear infinite;
	transition: 0.3s ease-in-out;

	color: #fff;
	background: linear-gradient(
		135deg,
		#612d8f 0%,
		#de005e 25%,
		/* #f66e48 50%, */ #de005e 75%,
		#612d8f 100%
	);
	opacity: 100%;
	background-size: 400%;
	border-radius: 15px;
}

.banner button svg {
	font-size: 25px;
	margin-left: 10px;
	transition: 0.3s ease-in-out;
	line-height: 1;
}
.banner button:hover {
	background: black;
	color: #fff;
	opacity: 75%;
	border-radius: 15px;
	transition: 0.3s ease-in-out;
	margin-left: 10px;
	border: 0.5px solid black;
	animation: none;
}

.banner img {
	animation: updown 3s linear infinite;
}
@keyframes updown {
	0% {
		transform: translateY(-20px);
	}
	50% {
		transform: translateY(20px);
	}
	100% {
		transform: translateY(-20px);
	}
}
.txt-rotate > .wrap {
	border-right: 0.08em solid #666;
}

@media screen and (max-width: 768px) {
	.banner {
		padding: 120px 0 100px 0;

		background-position: top 30% center;
		background-size: auto;
	}

	.banner h1 {
		font-size: 40px;
		font-weight: 500;
	}
	.banner p {
		font-size: 18px;
		margin-top: 15px;
		/* color: #612d8f; */
	}
	.banner button {
		padding: 5px;
		border-radius: 9px;
		font-size: 14px;
	}

	.banner button:hover {
		border-radius: 9px;
		margin-left: 5px;
		/* border: 0.1px solid #612d8f; */
	}
}

@media (min-width: 576px) and (max-width: 992px) {
	.banner {
		width: 100%;
	}
	.banner p {
		font-size: 22px;
		margin-top: 15px;
		/* color: #612d8f; */
	}
	.banner h1 {
		font-size: 55px;
		font-weight: 500;
	}
}
