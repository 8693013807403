.aboutus {
	padding: 40px 0 40px 0;
	position: relative;
	display: flex;
}

@keyframes animate {
	100% {
		background-position: 800%;
	}
}
.aboutus img {
	width: 30%;
}
.aboutus h2 {
	font-size: 45px;
	font-weight: 700;
	text-align: center;
	background: linear-gradient(
		135deg,
		#612d8f 0%,
		#de005e 25%,
		#f66e48 50%,
		#de005e 75%,
		#612d8f 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.aboutus-gradient {
	background: linear-gradient(
		135deg,
		#612d8f 0%,
		#de005e 25%,
		#f66e48 50%,
		#de005e 75%,
		#612d8f 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 700;
}

.aboutus-container-p {
	/* background-image: url('../assets/img/logo.png'); */
	/* background-image: radial-gradient(
		circle farthest-corner at 10% 20%,
		rgba(0, 152, 155, 1) 0.1%,
		rgba(0, 94, 120, 1) 94.2%
	); */
	/* padding: 10px; */
	border-radius: 14px;
	place-items: center;
	text-align: center justify;
	/* -webkit-background-clip: text;
	-webkit-text-fill-color: transparent; */
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
}
.aboutus p {
	font-size: 18px;
	line-height: 1.5em;
	text-align: center justify;
	/* width: 500px; */
}

/*aboutus Card*/
.proj-imgbx {
	position: relative;
	border-radius: 30px;
	overflow: hidden;
	min-height: 550px;
	margin: 50px 0 10px;
	background: #fff;
	background-size: cover;
	background-position: center;
	/* height: 600px; */
}
.proj-img {
	width: 100%;
	min-height: 200px;
	max-height: 400px;
}

.proj-imgbx:hover::before {
	height: 100%;
}
.proj-txtx {
	text-align: center;
	margin: 20px 0 0 10px;
	width: 100%;

	/* color: #fff; */
}
.proj-imgbx:hover .proj-txtx {
	top: 50%;
	opacity: 1;
}
.proj-txtx h3 {
	font-size: 30px;
	font-weight: 700;
	/* letter-spacing: 0.8px;
	line-height: 1.1em; */
	text-align: center;
	/* margin: 0 0 0 0; */
}
.proj-txtx p {
	font-size: 15px;
	text-align: Left;
	/* background: red; */
	width: 90%;
	/* margin: 10px;
	padding: 1000 px; */
}
.proj-txtx span {
	font-style: italic;
	font-weight: 400;
	font-size: 13px;
	letter-spacing: 0.8px;
	text-align: center;
}

@media screen and (max-width: 768px) {
	.aboutus h2 {
		font-size: 35px;
	}
	.aboutus p {
		font-size: 15px;
		line-height: 1.5em;
		text-align: center justify;
		/* width: 500px; */
	}
}

@media (min-width: 576px) and (max-width: 992px) {
}
