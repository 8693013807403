.contact {
	padding: 60px 0 60px 0;
}
.contact img {
	height: 500px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	margin-left: -100px;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
	margin: 20px 0 30px 0;
	background: linear-gradient(
		135deg,
		#612d8f 0%,
		#de005e 25%,
		#f66e48 50%,
		#de005e 75%,
		#612d8f 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.contact form input,
.contact form textarea {
	width: 90%;
	background: rgba(255, 255, 255, 0.1);
	/* background: #faf9f6; */
	border: 1px solid black;
	border-radius: 20px;
	color: black !important;
	margin: 0 0 8px 14px;
	padding: 18px 26px;
	font-weight: 500;
	font-size: 18px;
	letter-spacing: 0.8px;
	transition: 0.3s ease-in-out;
}
.contact form input:focus,
.contact form textarea:focus {
	color: #fff;
	background-color: white;
}
.contact form input::placeholder,
.contact form textarea::placeholder {
	font-size: 16px;
	font-weight: 400;
	color: #612d8f !important;
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
	opacity: 0.8;
	color: #612d8f;
}
.contact form button {
	font-weight: 700;
	color: #fff;
	background-color: #612d8f;
	padding: 14px 48px;
	font-size: 18px;
	margin-top: 25px;
	position: relative;
	transition: 0.3s ease-in-out;
	width: 40%;
	align-items: center;
	margin-left: 50px;
	border-radius: 15px;
	border: 1px solid #612d8f;
}
.contact form button span {
	z-index: 1;
	position: relative;
}
.contact form button:hover {
	color: #612d8f;
	background: #fff;
}
.contact form button::before {
	content: '';
	width: 0;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
	width: 100%;
}

@media screen and (max-width: 768px) {
	.contact img {
		display: none;
	}
	.contact h2 {
		font-size: 35px;
		font-weight: 500;
		margin: 20px 0 30px 0;
	}
	.contact form button {
		padding: 7px;
		font-size: 15px;
	}

	.contact form input,
	.contact form textarea {
		width: 90%;
		border-radius: 20px;
		margin: 0 0 8px 14px;
		padding: 10px 16px;
		font-weight: 500;
		font-size: 15px;
		height: 10%;
	}
	.contact {
		padding: 60px 0 60px 0;
	}
}

@media (min-width: 576px) and (max-width: 992px) {
}
