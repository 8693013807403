.footer {
	background-color: #faf9f6 !important;
	padding-top: 0.5px;
	color: #612d8f !important;
}

.footer img {
	width: 70px;
	background-color: white;
	border-radius: 50%;
}
.footer p {
	font-weight: 400;
	font-size: 14px;
	/* color: #b8b8b8; */
	letter-spacing: 0.5px;
	margin-top: 0px;
}

@media screen and (max-width: 768px) {
}
