.portfolio {
	padding: 80px;
	position: relative;
}
.portfolio-bx {
	background: #fff;
	border-radius: 64px;
	text-align: center;
	padding: 60px 80px;
	/* margin-top: -100px; */
}

.portfolio h2 {
	font-size: 45px;
	font-weight: 700;
	padding-bottom: 10px;
}
.portfolio p {
	color: #b8b8b8;
	font-size: 18px;
	letter-spacing: 0.8px;
	line-height: 1.5em;
	margin: 20px 0 20px 0;
}
.portfolio-gradient {
	background: linear-gradient(
		135deg,
		#612d8f 0%,
		#de005e 25%,
		#f66e48 50%,
		#de005e 75%,
		#612d8f 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.portfolio-slider {
	margin: 0;
	position: relative;
}
.portfolio-slider img {
	width: 100%;
	margin: 0 5px 20px 5px;
	padding: 5px;
}
.slider-img {
	/* background-color: red; */
	width: 300px;
	height: 300px;
}

.footnote {
	font-style: italic;
	font-size: 15px;
	opacity: 85%;
}

.modal-body {
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@media screen and (max-width: 768px) {
	.portfolio {
		padding: 15px;
		position: relative;
	}
	.portfolio-bx {
		background: #fff;
		border-radius: 24px;
		text-align: center;
		padding: 15px 15px;
		/* margin-top: -100px; */
	}

	.footnote {
		font-style: italic;
		font-size: 15px;
		opacity: 70%;
	}
	.portfolio-slider img {
		width: 100%;
		/* margin: 0 5px 20px 5px; */
		padding: 10px;
	}

	.slider-img {
		/* background-color: red; */
		width: 100%;
		height: 200px;
	}

	.portfolio h2 {
		font-size: 35px;
		font-weight: 500;
		padding-bottom: 15px;
	}
}

@media (min-width: 576px) and (max-width: 992px) {
	.portfolio-bx {
		background: #fff;
		border-radius: 64px;
		text-align: center;

		/* margin-top: -100px; */
	}
}
