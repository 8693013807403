nav.navbar {
	padding: 5px 0;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 9999;
	transition: 0.32s ease-in-out;
	flex: flex-start;
	color: #fff !important;
	box-shadow: none;
}
.navbar img {
	width: 60px;
	background-color: #fff;
	border-radius: 50%;
}
.navbar-brand {
	font-weight: 700;
	letter-spacing: 0px;
	line-height: 1;
	display: inline;
	color: #612d8f !important;
}
nav.navbar.scrolled {
	padding: 0px 0;
	background-color: #faf9f6;
}
nav.navbar a.navbar-brand {
	/* width: 50%; */
	background: linear-gradient(
		135deg,
		#612d8f 0%,
		#de005e 25%,
		#f66e48 50%,
		#de005e 75%,
		#612d8f 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: animate 10s linear infinite;
	overflow-wrap: break-word;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
	font-weight: 450;
	color: #612d8f !important;
	padding: 0 25px;
	font-size: 20px;
	opacity: 0.4;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
	opacity: 100%;
	transition: 0.3s ease-in-out;
}
span.navbar-text {
	display: flex;
	align-items: center;
}
.navbar-text button {
	font-weight: 500;
	border: 0.3px solid #612d8f;

	padding: 10px 20px;
	font-size: 18px;
	margin-left: 18px;
	position: relative;
	color: #fff;
	background: linear-gradient(
		135deg,
		#612d8f 0%,
		#de005e 25%,
		#f66e48 50%,
		#de005e 75%,
		#612d8f 100%
	);
	background-size: 700%;
	animation: animate 10s linear infinite;
	border-radius: 15px;
}
.navbar-text button span {
	z-index: 1;
}
.navbar-text button::before {
	content: '';
	width: 0%;
	height: 100%;
	position: absolute;
	background-color: #612d8f;
	border-radius: 15px;
	left: 0;
	top: 0;
	z-index: -1;
	transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
	animation: none;

	transition: 0.3s ease-in-out;
	margin-left: 15px;
	margin-bottom: 5px;

	color: #fff;
	background: black;
	opacity: 75%;
	border-radius: 15px;
	border: 1px solid black;
}
@keyframes animate {
	100% {
		background-position: 400%;
	}
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
	box-shadow: none;
}
/* 
nav.navbar .navbar-toggler-icon {
	width: 24px;
	height: 17px;
	background-image: none;
	background-color: #612d8f;
	position: relative;
	border-bottom: 2px solid #612d8f;
	transition: all 300ms linear;
	top: -2px;
	color: #612d8f;
	background-color: #de005e;
}
nav.navbar .navbar-toggler-icon:focus {
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #612d8f;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded='true'] .navbar-toggler-icon {
	border-color: transparent;
}

 */

@media screen and (max-width: 768px) {
	.social-icon a {
		width: 32px;
		height: 32px;
	}
	.social-icon a::before {
		content: '';
		width: 32px;
		height: 32px;
	}
	.navbar-text button {
		font-size: 14px;
		padding: 8px 16px;
	}
	.navbar img {
		width: 42px;

		border-radius: 50%;
	}
	a.navbar-brand {
		font-size: 15px;
	}
}

@media (min-width: 576px) and (max-width: 992px) {
	.social-icon a {
		width: 32px;
		height: 32px;
	}
	.social-icon a::before {
		content: '';
		width: 32px;
		height: 32px;
	}
	.navbar-text button {
		font-size: 14px;
		padding: 8px 16px;
	}
	.navbar img {
		width: 30px;
		border-radius: 50%;
	}
}
